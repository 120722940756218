import React, { Component } from "react";
import StarRatings from "react-star-ratings";
import { connect } from "react-redux";
import {
  onAuthFieldChanged,
  toggleNotification,
  countIncrease,
  fetchUserDetails,
} from "../../actions";

class Foo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0, // initial rating value
    };
    this.changeRating = this.changeRating.bind(this);
  }

  changeRating(newRating, name) {
    this.props.onAuthFieldChanged("rating", newRating);
  }

  render() {
    return (
      <StarRatings
        rating={this.props.rating}
        starRatedColor="orange"
        changeRating={this.changeRating}
        numberOfStars={5}
        name="rating"
        starDimension="2.5vw"
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    rating: state.auth.rating,
  };
}

export default connect(mapStateToProps, {
  onAuthFieldChanged,
})(Foo);
