import React, { useEffect } from "react"
import Navbar from "../global-components/navbar"
import Page_header from "../global-components/page-header"
import Footer_v1 from "../global-components/footer"

function TermsConditionPopup() {
  const headStyle = { fontSize: "25px", fontWeight: "bold", margin: "4vh 0" }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navbar />
      <Page_header
        headertitle='Terms & Conditions'
        backgroundImage='https://delhibycycle.s3.ap-south-1.amazonaws.com/being-a-female-tour-guide-in-india-header-blog-delhi-by-cycle.jpg'
      />

      <div
        className='terms-popup'
        style={{
          position: "relative",
          margin: "0 auto",
          width: "80%",
          margin: "5vh auto",
        }}
      >
        <div
          className='pgcon1'
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <h1 style={{ margin: "10vh" }}>Terms & Conditions</h1>

          <h3 style={headStyle}>Introduction</h3>
          <p>
            Please carefully read the following terms and conditions, as your
            use of the service is contingent upon your acceptance and adherence
            to these terms.
          </p>
          <p>
            By booking any of the services offered on our website, you accept
            without reservation the obligations set out in our General Terms and
            Conditions of Sale.{" "}
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>{" "}
            provides the services advertised on our website by these Terms and
            Conditions.
          </p>
          <p>
            By accessing this website and requesting the services we offer, you
            agree to accept all of the General Terms and Conditions that are
            published at the time. Therefore, it is essential to read the
            General Terms and Conditions each time prior to placing a purchase
            order.{" "}
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>{" "}
            (Brand registered under Jack’s Adventures Private Limited) is
            responsible for all transactions conducted through its website,
            including providing customer support, handling disputes, issuing
            refunds, and managing cancellations.
          </p>

          <h3 style={headStyle}>Booking Regulations</h3>
          <p>
            While making a booking, the first person listed on the reservation
            agrees on behalf of all the people listed on the booking that:
          </p>
          <p>
            (1) He or she authorizes all individuals mentioned on the booking to
            divulge their personal information to us, including any applicable
            special categories of data (such as health information), and
            consents to our use of that information in line with our Privacy
            Policy.
          </p>
          <p>
            (2) He or she has read these Booking Terms and general information
            of our tours as mentioned on our website (
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>
            ), and Tour Costs, and is able and willing to bind himself or
            herself by them.
          </p>
          <p>
            (3) He or she understands and accepts the risks associated with the
            kind of tour selected by them. They also need to fill out the
            indemnity form before starting any cycle tour.
          </p>
          <p>
            (4) They declare that they, and all members of their party, are over
            18 years of age when placing an order for services with age
            restrictions. Members below 18 years of age can only join the
            activity/tour with their parents/guardians who are of legal age to
            participate in the activity chosen by them.
          </p>
          <p>
            (5) They should not have any pre-existing medical condition or
            disability that would prevent them from actively participating in
            the tour. If anyone has a pre-existing medical condition or
            disability, please contact us before making a booking to determine
            the suitability of the tour arrangements.
          </p>
          <p>
            (6) He or She affirms that while making a booking they assume
            financial responsibility for payment for all individuals listed in
            the booking.
          </p>
          <p>
            In addition - Any booking made by or for you will be regarded as
            having been made by you. If there is a dispute about payment for a
            prior order or if any irregularity is suspected,
            www.delhibycycle.com reserves the right to cancel the booking.
          </p>
          <p>
            When you submit your booking request, we consider this to be an
            unsecured booking. It is not confirmed yet. A contract between you
            and us is only established when we send an email to notify that your
            booking has been secured. This is when your booking is definitely
            accepted.
          </p>
          <p>
            Upon making a booking, you will receive an email confirmation of the
            booking from{" "}
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>{" "}
            on behalf of our partner/guide, indicating that the booking has been
            accepted and confirmed. This means that you need to regularly check
            your email. If for any reason we are unable to reach you by email,
            we may still reach out to you by phone and/or other contact details
            provided by you.
          </p>
          <p>
            If an event or occasion occurs on the day of the tour, or any other
            unpredictable event, that makes it impossible or inadvisable to
            continue the tour,{" "}
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>{" "}
            and/or its partner may cancel the booking up to the time of the
            tour's commencement. Please note that the booking is always subject
            to availability.
          </p>

          <h3 style={headStyle}>Payments Terms and Price</h3>
          <p>
            At{" "}
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>
            , you can expect to receive pricing and cost information for any
            services you may be interested in. The pricing information can be
            found when viewing the advertisement for a particular service.
            Generally, we will display the total cost or the base cost with any
            taxes, fees, and additional costs that may be associated with the
            service. You may also be able to find a breakdown of each of the
            costs of the services on the advertisement page. We provide a secure
            online payment system, where you can pay for services securely and
            quickly. This payment system is safe and encrypted so you don’t have
            to worry about your financial data being compromised. After payment
            is complete, you will be notified with a receipt for your services.
          </p>
          <p>
            The price stated at the time of booking will be the final cost.
            Payment for these services must be made via our website unless
            instructed to pay by other means for the convenience of the
            customer. We retain the right to consider your booking canceled if
            we do not receive this balance in whole and on schedule.
          </p>
          <p>
            The Tour Operator is responsible for ensuring that the cost of the
            Tour remains the same as the advertised and paid-for price. This
            includes all applicable taxes, fees, and service charges related to
            the Tour. The Tour Operator will not raise the price of the Tour
            after the full amount has been paid, ensuring that the Tour remains
            the same cost that was previously agreed upon.
          </p>
          <p>
            Payment for services provided by our company can be made through our
            website (
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>
            ), although certain discounts and conditions may offer an
            alternative payment option.
          </p>

          <h3 style={headStyle}>Cancellation and refund</h3>
          <p>
            If you or any other member of your party wishes to cancel your
            confirmed booking, you must inform us about the cancellation through
            email. Such notice of cancellation will be effective from the date
            we receive it through the email.
          </p>
          <p>
            The Company requires full payment to be made in advance before the
            tour. Tour bookings will only be confirmed once the full payment has
            been received. In some cases, other payment methods may be offered
            for customer convenience.
          </p>
          <p>
            Refunds for canceled tours will be processed within 15 days of
            cancellation, following the cancellation policy.
          </p>
          <p>
            In case of cancellations, there will be a minimum 10% deduction for
            online charges, taxes, and other miscellaneous charges. Further
            refund policy is as under:
          </p>
          <p>
            <b>City Cycle Tours & Walking Tours</b>
            <ul>
              <li>3 Days before the tour - 90% refund</li>
              <li>2 Days before the tour - 60% refund</li>
              <li>12 Hours before the tour - 30% refund</li>
              <li>Once the tour commences - zero refund</li>
            </ul>
          </p>
          <p>
            <b>Cycling Holidays</b>
            <ul>
              <li>30 Days before the tour - 80% refund</li>
              <li>15 Days before the tour - 50% refund</li>
              <li>5 Days before the tour - 30% refund</li>
              <li>Once the tour commences - zero refund</li>
            </ul>
          </p>
          <p>
            In the event of circumstances beyond our control, such as an act of
            God, riots, war, strikes, civil unrest, terrorism, governmental
            regulations, floods, earthquakes, fire, and other natural disasters,
            tours may be canceled at the Company’s sole discretion without
            notice. Any actual costs incurred on special arrangements made for
            the tour (if any), including cancellation charges on vehicles
            booked, and tour leaders allocated, will be deducted.
          </p>
          <p>
            If our company has to cancel a tour for any reason, it has the right
            to do so without any prior notice to the guest. Upon cancellation,
            the guest will receive a full refund of the fee paid. Cancellation
            may be necessary in cases where there are unforeseen natural weather
            conditions in the location of the tour or any other harmful
            condition that makes it unsafe for the tour to be conducted, or any
            other emergency situation where the Company deems it necessary to
            take such action. All cancellations and refunds will be done in
            accordance with applicable laws and regulations.
          </p>

          <h3 style={headStyle}>Accuracy not guaranteed</h3>
          <p>
            delhibycycle.com makes no guarantees regarding the accuracy of the
            Tour/Service/Product as ordered by the User. Because some of the
            experiences and services that delhibycycle.com provides services for
            involve many 3rd party vendors, it is possible that some changes may
            occur to the user's order due to circumstances beyond their control.
            For example, their venue may be unavailable, breakfast or
            refreshment place shut, the workshop or service provider may not be
            available, or other alterations may be necessary. As a result of
            these situations, certain aspects of the user's order can change,
            such as plans or arrangements.{" "}
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              delhibycycle.com
            </a>{" "}
            is not able to guarantee that all aspects of the user's order will
            remain accurate and unchanged, and the user should understand that
            potential changes may occur. Furthermore, delhibycycle.com cannot
            guarantee that the quality of the Services, information, or other
            materials obtained through the website will be up to the user's
            expectations.
          </p>
          <p>
            In this case you agree that a representative from{" "}
            <a href='https://www.delhibycycle.com/ ' style={{ color: "blue" }}>
              www.delhibycycle.com
            </a>{" "}
            will inform you through the call or email address you provided when
            placing your order, either to inform you or to send an approval
            request.
          </p>

          <h3 style={headStyle}>Property Rights</h3>
          <p>
            The Company’s intellectual property refers to any intangible works
            or creations that are the exclusive intellectual property of the
            Company, protected by the laws of copyright, trademarks, and
            patents. This intellectual property includes the Company’s
            distinctive logos, visual images, text, publications, designs, and
            other forms of creative output registered trademarks, such as the
            words and symbols used to identify the Company’s products and
            services and/or as a source of origin. It also includes creative
            works that the Company has protected under copyright laws, such as
            articles, photographs, books, video and audio recordings, computer
            software, and other creative works.
          </p>
          <p>
            Any use of the Company’s intellectual property for any other purpose
            requires the Company’s prior written approval. This approval might
            be necessary for an individual or business to promote or market
            their own products or services using the Company’s intellectual
            property. It may also be necessary for an individual or business to
            publish or reprint works protected by the Company’s copyright.
          </p>
          <p>
            Furthermore, the Company’s approval may be necessary for another
            individual or business to use any of the Company’s protected
            trademarks either to advertise their own products or services or to
            manufacture a product or service using the protected mark. All forms
            of the Company’s protected intellectual property should be
            respected. Any unauthorised use of the Company’s intellectual
            property may result in legal action being taken against the
            violator. Therefore, it is important to obtain the Company’s written
            approval before utilising any of the Company’s intellectual
            property.
          </p>
        </div>
      </div>
      <Footer_v1 />
    </>
  )
}

export default TermsConditionPopup
